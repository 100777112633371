import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  const response = await axios.get(`${url}/events`, options);
  return {
    list: jsona.deserialize(response.data),
    meta: response.data.meta,
  };
}

async function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios.get(`${url}/events/${id}`, options);
  let event = jsona.deserialize(response.data);
  delete event.links;
  return event;
}

async function add(event) {
  const payload = jsona.serialize({
    stuff: event,
    includeNames: null,
  });
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios.post(`${url}/events`, payload, options);
  return jsona.deserialize(response.data);
}

async function update(event) {
  const payload = jsona.serialize({
    stuff: event,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios.patch(
    `${url}/events/${event.id}`,
    payload,
    options
  );
  return jsona.deserialize(response.data);
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/events/${id}`, options);
}

function deleteAllByParent(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/events/${id}/delete-series`, options);
}

async function reorganizeVehicles(data) {
  const { eventId, ...rest } = data;

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios.post(
    `${url}/events/${eventId}/reorganize-vehicles`,
    rest,
    options
  );

  return jsona.deserialize(response.data);
}

async function assignVehicles(data) {
  const { eventId, ...rest } = data;

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios.post(
    `${url}/events/${eventId}/assign-vehicles`,
    rest,
    options
  );

  return jsona.deserialize(response.data);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  deleteAllByParent,
  reorganizeVehicles,
  assignVehicles,
};
