import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/negotiations`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/negotiations/${id}?include=vehicle,sellerOrganization,sellerLocation,buyerOrganization,buyerLocation`,
      options
    )
    .then((response) => {
      let negotiation = jsona.deserialize(response.data);
      delete negotiation.links;
      return negotiation;
    });
}

function convertVehicleBooleans(negotiation) {
  const convertedVehicle = { ...negotiation };

  Object.keys(convertedVehicle).forEach((key) => {
    const value = convertedVehicle[key];
    if (typeof value === "string" && (value === "true" || value === "false")) {
      convertedVehicle[key] = value === "true";
    }
  });

  return convertedVehicle;
}

function add(negotiation) {
  var vehiculeConverted = convertVehicleBooleans(negotiation);

  const payload = jsona.serialize({
    stuff: vehiculeConverted,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/negotiations?include=vehicle,sellerOrganization,sellerLocation,buyerOrganization,buyerLocation`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(negotiation) {
  const payload = jsona.serialize({
    stuff: negotiation,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/negotiations/${negotiation.id}?include=vehicle,sellerOrganization,sellerLocation,buyerOrganization,buyerLocation`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/negotiations/${id}`, options);
}

function closeSale(negotiation) {
  const { id, ...data } = negotiation;

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/negotiations/${id}/close-sale`, data, options)
    .then((response) => response);
}

export default {
  list,
  get,
  add,
  print,
  update,
  destroy,
  closeSale,
};
