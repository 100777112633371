export default {
  PERM_VIEW_APP_DEFAULT: "view app default",
  PERM_VIEW_APP_CRM: "view app crm",
  PERM_VIEW_APP_SALES: "view app sales",
  PERM_VIEW_APP_PURCHASES: "view app purchases",
  PERM_VIEW_APP_INVENTORY: "view app inventory",
  PERM_VIEW_APP_FILE_EXPLORER: "view app file explorer",
  PERM_VIEW_APP_RESELLER: "view app reseller",
  PERM_VIEW_APP_ADMIN: "view app admin",
  PERM_VIEW_APP_AUCTION: "view app auction",

  PERM_VIEW_MODULE_SUBSCRIPTIONS: "view module subscriptions",
  PERM_VIEW_ANY_SUBSCRIPTIONS: "view any subscriptions",
  PERM_VIEW_SUBSCRIPTIONS: "view subscriptions",
  PERM_CREATE_SUBSCRIPTIONS: "create subscriptions",
  PERM_EDIT_SUBSCRIPTIONS: "edit subscriptions",
  PERM_DELETE_SUBSCRIPTIONS: "delete subscriptions",

  PERM_VIEW_MODULE_PACKAGES: "view module packages",
  PERM_VIEW_ANY_PACKAGES: "view any packages",
  PERM_VIEW_PACKAGES: "view packages",
  PERM_CREATE_PACKAGES: "create packages",
  PERM_EDIT_PACKAGES: "edit packages",
  PERM_DELETE_PACKAGES: "delete packages",

  PERM_VIEW_MODULE_CUSTOMERS: "view module customers",
  PERM_VIEW_ANY_CUSTOMERS: "view any customers",
  PERM_VIEW_CUSTOMERS: "view customer",
  PERM_CREATE_CUSTOMERS: "create customer",
  PERM_EDIT_CUSTOMERS: "edit customer",
  PERM_DELETE_CUSTOMERS: "delete customer",

  PERM_VIEW_MODULE_ORGANIZATIONS: "view module organizations",
  PERM_VIEW_ANY_ORGANIZATIONS: "view any organizations",
  PERM_VIEW_ORGANIZATIONS: "view organization",
  PERM_CREATE_ORGANIZATIONS: "create organization",
  PERM_EDIT_ORGANIZATIONS: "edit organization",
  PERM_EDIT_OWN_ORGANIZATIONS: "edit own organization",
  PERM_DELETE_ORGANIZATIONS: "delete organization",

  PERM_VIEW_MODULE_ORGANIZATIONGROUPS: "view module groupe organization",
  PERM_VIEW_ANY_ORGANIZATIONGROUPS: "view any groupe organization",
  PERM_VIEW_ORGANIZATIONGROUPS: "view groupe organization",
  PERM_CREATE_ORGANIZATIONGROUPS: "create groupe organization",
  PERM_EDIT_ORGANIZATIONGROUPS: "edit groupe organization",
  PERM_EDIT_OWN_ORGANIZATIONGROUPS: "edit own groupe organization",
  PERM_DELETE_ORGANIZATIONGROUPS: "delete groupe organization",

  PERM_VIEW_MODULE_RESELLERS: "view module resellers",
  PERM_VIEW_ANY_RESELLERS: "view any resellers",
  PERM_VIEW_RESELLERS: "view resellers",
  PERM_CREATE_RESELLERS: "create resellers",
  PERM_EDIT_RESELLERS: "edit resellers",
  PERM_EDIT_OWN_RESELLERS: "edit own resellers",
  PERM_DELETE_RESELLERS: "delete resellers",

  PERM_VIEW_MODULE_RESELLER_PRODUCTS: "view module reseller products",
  PERM_VIEW_ANY_RESELLER_PRODUCTS: "view any reseller products",
  PERM_VIEW_RESELLER_PRODUCTS: "view reseller products",
  PERM_CREATE_RESELLER_PRODUCTS: "create reseller products",
  PERM_EDIT_RESELLER_PRODUCTS: "edit reseller products",
  PERM_DELETE_RESELLER_PRODUCTS: "delete reseller products",

  PERM_VIEW_MODULE_RESELLER_SERVICES: "view module reseller services",
  PERM_VIEW_ANY_RESELLER_SERVICES: "view any reseller services",
  PERM_VIEW_RESELLER_SERVICES: "view reseller services",
  PERM_CREATE_RESELLER_SERVICES: "create reseller services",
  PERM_EDIT_RESELLER_SERVICES: "edit reseller services",
  PERM_DELETE_RESELLER_SERVICES: "delete reseller services",

  PERM_VIEW_MODULE_RESELLER_INVOICES: "view module reseller invoices",
  PERM_VIEW_ANY_RESELLER_INVOICES: "view any reseller invoices",
  PERM_VIEW_RESELLER_INVOICES: "view reseller invoice",
  PERM_CREATE_RESELLER_INVOICES: "create reseller invoice",
  PERM_EDIT_RESELLER_INVOICES: "edit reseller invoice",
  PERM_DELETE_RESELLER_INVOICES: "delete reseller invoice",

  PERM_VIEW_MODULE_RESELLER_PAYMENTS: "view module reseller payments",
  PERM_VIEW_ANY_RESELLER_PAYMENTS: "view any reseller payments",
  PERM_VIEW_RESELLER_PAYMENTS: "view reseller payments",
  PERM_CREATE_RESELLER_PAYMENTS: "create reseller payments",

  PERM_VIEW_MODULE_USERS: "view module users",
  PERM_VIEW_ANY_USERS: "view any users",
  PERM_VIEW_USERS: "view users",
  PERM_CREATE_USERS: "create users",
  PERM_EDIT_USERS: "edit users",
  PERM_DELETE_USERS: "delete users",

  PERM_VIEW_MODULE_PRODUCTS: "view module products",
  PERM_VIEW_ANY_PRODUCTS: "view any products",
  PERM_VIEW_PRODUCTS: "view products",
  PERM_CREATE_PRODUCTS: "create products",
  PERM_EDIT_PRODUCTS: "edit products",
  PERM_DELETE_PRODUCTS: "delete products",

  PERM_VIEW_MODULE_ROLES: "view module roles",
  PERM_VIEW_ANY_ROLES: "view any roles",
  PERM_VIEW_ROLES: "view roles",
  PERM_CREATE_ROLES: "create roles",
  PERM_EDIT_ROLES: "edit roles",
  PERM_DELETE_ROLES: "delete roles",

  PERM_VIEW_ANY_PERMISSIONS: "view any permissions",
  PERM_VIEW_PERMISSIONS: "view permissions",

  PERM_VIEW_ANY_LOGS: "view any logs",
  PERM_VIEW_LOGS: "view logs",

  PERM_VIEW_MODULE_CONTACTS: "view module contacts",
  PERM_VIEW_ANY_CONTACTS: "view any contacts",
  PERM_VIEW_CONTACTS: "view contacts",
  PERM_CREATE_CONTACTS: "create contacts",
  PERM_EDIT_CONTACTS: "edit contacts",
  PERM_DELETE_CONTACTS: "delete contacts",

  PERM_VIEW_MODULE_LABELS: "view module labels",
  PERM_VIEW_ANY_LABELS: "view any labels",
  PERM_VIEW_LABELS: "view labels",
  PERM_CREATE_LABELS: "create labels",
  PERM_EDIT_LABELS: "edit labels",
  PERM_DELETE_LABELS: "delete labels",

  PERM_VIEW_MODULE_CHARGE_PACKAGES: "view module charge_packages",
  PERM_VIEW_ANY_CHARGE_PACKAGES: "view any charge_packages",
  PERM_VIEW_CHARGE_PACKAGES: "view charge_packages",
  PERM_CREATE_CHARGE_PACKAGES: "create charge_packages",
  PERM_EDIT_CHARGE_PACKAGES: "edit charge_packages",
  PERM_DELETE_CHARGE_PACKAGES: "delete charge_packages",

  PERM_VIEW_MODULE_CHARGES: "view module charges",
  PERM_VIEW_ANY_CHARGES: "view any charges",
  PERM_VIEW_CHARGES: "view charges",
  PERM_CREATE_CHARGES: "create charges",
  PERM_EDIT_CHARGES: "edit charges",
  PERM_DELETE_CHARGES: "delete charges",

  PERM_VIEW_MODULE_TAXES: "view module taxes",
  PERM_VIEW_ANY_TAXES: "view any taxes",
  PERM_VIEW_TAXES: "view taxes",
  PERM_CREATE_TAXES: "create taxes",
  PERM_EDIT_TAXES: "edit taxes",
  PERM_DELETE_TAXES: "delete taxes",

  PERM_VIEW_MODULE_CONTRACTS: "view module contracts",
  PERM_VIEW_ANY_CONTRACTS: "view any contracts",
  PERM_VIEW_CONTRACTS: "view contracts",
  PERM_CREATE_CONTRACTS: "create contracts",
  PERM_EDIT_CONTRACTS: "edit contracts",
  PERM_DELETE_CONTRACTS: "delete contracts",

  PERM_VIEW_MODULE_CONTRACT_FILES: "view module contract files",
  PERM_VIEW_ANY_CONTRACT_FILES: "view any contract files",
  PERM_VIEW_CONTRACT_FILES: "view contract files",
  PERM_CREATE_CONTRACT_FILES: "create contract files",
  PERM_EDIT_CONTRACT_FILES: "edit contract files",
  PERM_DELETE_CONTRACT_FILES: "delete contract files",
  
  PERM_VIEW_MODULE_SIGNATURES: "view module signatures",
  PERM_VIEW_ANY_SIGNATURES: "view any signatures",
  PERM_VIEW_SIGNATURES: "view signatures",
  PERM_CREATE_SIGNATURES: "create signatures",
  PERM_EDIT_SIGNATURES: "edit signatures",
  PERM_DELETE_SIGNATURES: "delete signatures",

  PERM_VIEW_MODULE_PAYMENT_METHODS: 'view module payment methods',
  PERM_VIEW_ANY_PAYMENT_METHODS: 'view any  payment methods',
  PERM_VIEW_PAYMENT_METHODS: 'view  payment methods',
  PERM_CREATE_PAYMENT_METHODS: 'create  payment methods',
  PERM_EDIT_PAYMENT_METHODS: 'edit  payment methods',
  PERM_DELETE_PAYMENT_METHODS: 'delete  payment methods',
  PERM_VALIDATE_PAYMENT_METHODS: 'validate payment methods',

  PERM_VIEW_MODULE_SUPPLIERS: "view module suppliers",
  PERM_VIEW_ANY_SUPPLIERS: "view any suppliers",
  PERM_VIEW_SUPPLIERS: "view suppliers",
  PERM_CREATE_SUPPLIERS: "create suppliers",
  PERM_EDIT_SUPPLIERS: "edit suppliers",
  PERM_DELETE_SUPPLIERS: "delete suppliers",

  PERM_VIEW_MODULE_LOCATIONS: "view module locations",
  PERM_VIEW_ANY_LOCATIONS: "view any locations",
  PERM_VIEW_LOCATIONS: "view locations",
  PERM_CREATE_LOCATIONS: "create locations",
  PERM_EDIT_LOCATIONS: "edit locations",
  PERM_EDIT_HIS_LOCATIONS: "edit his locations",
  PERM_DELETE_LOCATIONS: "delete locations",

  PERM_VIEW_MODULE_OTHER_FEES: "view module other fees",
  PERM_VIEW_ANY_OTHER_FEES: "view any other fees",
  PERM_VIEW_OTHER_FEES: "view other fees",
  PERM_CREATE_OTHER_FEES: "create other fees",
  PERM_EDIT_OTHER_FEES: "edit other fees",
  PERM_DELETE_OTHER_FEES: "delete other fees",

  PERM_VIEW_ANY_BIDS: "view any bids",
  PERM_VIEW_BIDS: "view bids",
  PERM_CREATE_BIDS: "create bids",
  PERM_EDIT_BIDS: "edit bids",

  PERM_VIEW_MODULE_FILES: "view module files",
  PERM_VIEW_ANY_FILES: "view any files",
  PERM_VIEW_FILES: "view files",
  PERM_CREATE_FILES: "create files",
  PERM_EDIT_FILES: "edit files",
  PERM_DELETE_FILES: "delete files",
  PERM_MANAGE_ACCESS_FILES: "manage access files",

  PERM_VIEW_MODULE_FOLDERS: "view module folders",
  PERM_VIEW_ANY_FOLDERS: "view any folders",
  PERM_VIEW_FOLDERS: "view folders",
  PERM_CREATE_FOLDERS: "create folders",
  PERM_EDIT_FOLDERS: "edit folders",
  PERM_DELETE_FOLDERS: "delete folders",
  PERM_MANAGE_ACCESS_FOLDERS: "manage access folders",
  PERM_MANAGE_LOCKED_FOLDERS: "manage locked folders",

  PERM_VIEW_MODULE_WAREHOUSES: "view module warehouses",
  PERM_VIEW_ANY_WAREHOUSES: "view any warehouses",
  PERM_VIEW_WAREHOUSES: "view warehouses",
  PERM_CREATE_WAREHOUSES: "create warehouses",
  PERM_EDIT_WAREHOUSES: "edit warehouses",
  PERM_DELETE_WAREHOUSES: "delete warehouses",

  PERM_VIEW_MODULE_PURCHASES_ORDERS: "view module purchases orders",
  PERM_VIEW_ANY_PURCHASES_ORDERS: "view any purchases orders",
  PERM_VIEW_PURCHASES_ORDERS: "view purchases orders",
  PERM_CREATE_PURCHASES_ORDERS: "create purchases orders",
  PERM_EDIT_PURCHASES_ORDERS: "edit purchases orders",
  PERM_DELETE_PURCHASES_ORDERS: "delete purchases orders",

  PERM_VIEW_MODULE_PURCHASES_INVOICES: "view module purchases invoices",
  PERM_VIEW_ANY_PURCHASES_INVOICES: "view any purchases invoices",
  PERM_VIEW_PURCHASES_INVOICES: "view purchases invoices",
  PERM_CREATE_PURCHASES_INVOICES: "create purchases invoices",
  PERM_EDIT_PURCHASES_INVOICES: "edit purchases invoices",
  PERM_DELETE_PURCHASES_INVOICES: "delete purchases invoices",

  PERM_VIEW_MODULE_PURCHASES_DELIVERIES: "view module purchases deliveries",
  PERM_VIEW_ANY_PURCHASES_DELIVERIES: "view any purchases deliveries",
  PERM_VIEW_PURCHASES_DELIVERIES: "view purchases deliveries",
  PERM_CREATE_PURCHASES_DELIVERIES: "create purchases deliveries",
  PERM_EDIT_PURCHASES_DELIVERIES: "edit purchases deliveries",
  PERM_DELETE_PURCHASES_DELIVERIES: "delete purchases deliveries",

  PERM_VIEW_MODULE_PURCHASES_PAYMENTS: "view module purchases payments",
  PERM_VIEW_ANY_PURCHASES_PAYMENTS: "view any purchases payments",
  PERM_VIEW_PURCHASES_PAYMENTS: "view purchases payments",
  PERM_CREATE_PURCHASES_PAYMENTS: "create purchases payments",
  PERM_EDIT_PURCHASES_PAYMENTS: "edit purchases payments",
  PERM_DELETE_PURCHASES_PAYMENTS: "delete purchases payments",

  PERM_VIEW_MODULE_SALES_ORDERS: "view module sales orders",
  PERM_VIEW_ANY_SALES_ORDERS: "view any sales orders",
  PERM_VIEW_SALES_ORDERS: "view sales orders",
  PERM_CREATE_SALES_ORDERS: "create sales orders",
  PERM_EDIT_SALES_ORDERS: "edit sales orders",
  PERM_DELETE_SALES_ORDERS: "delete sales orders",

  PERM_VIEW_MODULE_SALES_INVOICES: "view module sales invoices",
  PERM_VIEW_ANY_SALES_INVOICES: "view any sales invoices",
  PERM_VIEW_SALES_INVOICES: "view sales invoices",
  PERM_CREATE_SALES_INVOICES: "create sales invoices",
  PERM_EDIT_SALES_INVOICES: "edit sales invoices",
  PERM_DELETE_SALES_INVOICES: "delete sales invoices",

  PERM_VIEW_MODULE_SALES_DELIVERIES: "view module sales deliveries",
  PERM_VIEW_ANY_SALES_DELIVERIES: "view any sales deliveries",
  PERM_VIEW_SALES_DELIVERIES: "view sales deliveries",
  PERM_CREATE_SALES_DELIVERIES: "create sales deliveries",
  PERM_EDIT_SALES_DELIVERIES: "edit sales deliveries",
  PERM_DELETE_SALES_DELIVERIES: "delete sales deliveries",

  PERM_VIEW_MODULE_SALES_PAYMENTS: "view module sales payments",
  PERM_VIEW_ANY_SALES_PAYMENTS: "view any sales payments",
  PERM_VIEW_SALES_PAYMENTS: "view sales payments",
  PERM_CREATE_SALES_PAYMENTS: "create sales payments",
  PERM_EDIT_SALES_PAYMENTS: "edit sales payments",
  PERM_DELETE_SALES_PAYMENTS: "delete sales payments",

  PERM_VIEW_MODULE_STOCK_MOVEMENTS: "view module stock movements",
  PERM_VIEW_ANY_STOCK_MOVEMENTS: "view any stock movements",
  PERM_VIEW_STOCK_MOVEMENTS: "view stock movements",
  PERM_CREATE_STOCK_MOVEMENTS: "create stock movements",
  PERM_EDIT_STOCK_MOVEMENTS: "edit stock movements",
  PERM_DELETE_STOCK_MOVEMENTS: "delete stock movements",

  PERM_VIEW_MODULE_IMPORTS: "view module imports",
  PERM_VIEW_ANY_IMPORTS: "view any imports",
  PERM_VIEW_IMPORTS: "view imports",
  PERM_CREATE_IMPORTS: "create imports",
  PERM_EDIT_IMPORTS: "edit imports",
  PERM_DELETE_IMPORTS: "delete imports",

  PERM_VIEW_MODULE_PROPERTIES: "view module properties",
  PERM_VIEW_ANY_PROPERTIES: "view any properties",
  PERM_VIEW_PROPERTIES: "view properties",
  PERM_CREATE_PROPERTIES: "create properties",
  PERM_EDIT_PROPERTIES: "edit properties",
  PERM_DELETE_PROPERTIES: "delete properties",
  PERM_TOGGLE_ACTIVATION_PROPERTIES: "toggle activation properties",
  PERM_ACCESS_ALL_FIELDS_PROPERTIES: "access all fields properties",
  PERM_EXPORTS_PROPERTIES: "exports properties",
  PERM_EDIT_PROPERTY_NUMBER_PROPERTIES: "edit property number properties",
  PERM_CHANGE_APPROVED_STATUS_PROPERTIES: "change approved status properties",

  PERM_VIEW_MODULE_AUCTIONS: "view module auctions",
  PERM_VIEW_ANY_AUCTIONS: "view any auctions",
  PERM_VIEW_AUCTIONS: "view auctions",
  PERM_CREATE_AUCTIONS: "create auctions",
  PERM_EDIT_AUCTIONS: "edit auctions",
  PERM_DELETE_AUCTIONS: "delete auctions",

  PERM_VIEW_MODULE_PROPERTY_TRANSACTIONS: "view module property transactions",
  PERM_VIEW_ANY_PROPERTY_TRANSACTIONS: "view any property transactions",
  PERM_VIEW_PROPERTY_TRANSACTIONS: "view property transactions",
  PERM_CREATE_PROPERTY_TRANSACTIONS: "create property transactions",
  PERM_EDIT_PROPERTY_TRANSACTIONS: "edit property transactions",
  PERM_DELETE_PROPERTY_TRANSACTIONS: "delete property transactions",
  PERM_VIEW_ANY_TRANSACTIONS_PROPERTIES: "view any transactions properties",
  PERM_CREATE_TRANSACTIONS_PROPERTIES: "create transactions properties",
  PERM_DELETE_TRANSACTIONS_PROPERTIES: "delete transactions properties",

  PERM_VIEW_MODULE_VEHICLES: "view module vehicles",
  PERM_VIEW_ANY_VEHICLES: "view any vehicles",
  PERM_VIEW_VEHICLES: "view vehicle",
  PERM_CREATE_VEHICLES: "create vehicle",
  PERM_EDIT_VEHICLES: "edit vehicle",
  PERM_EDIT_OWN_VEHICLES: "edit own vehicle",
  PERM_DELETE_VEHICLES: "delete vehicle",
  PERM_INVALIDATE_VEHICLES: "invalidate vehicle",
  PERM_CLOSE_VEHICLE_AUCTION: "close vehicle auction",
  PERM_CHANGE_RDPRM_STATUS_VEHICLES : 'change rdprm status vehicle',
  PERM_CHANGE_DOCUMENTS_STATUS_VEHICLES : 'change documents status vehicle',

  PERM_VIEW_MODULE_INVITATIONS: "view module invitations",
  PERM_VIEW_ANY_INVITATIONS: "view any invitations",
  PERM_VIEW_INVITATIONS: "view invitation",
  PERM_CREATE_INVITATIONS: "create invitation",
  PERM_EDIT_INVITATIONS: "edit invitation",
  PERM_DELETE_INVITATIONS: "delete invitation",

  PERM_VIEW_MODULE_TRANSACTIONS: "view module transactions",
  PERM_VIEW_ANY_TRANSACTIONS: "view any transactions",
  PERM_VIEW_TRANSACTIONS: "view transactions",
  PERM_CREATE_TRANSACTIONS: "create transactions",
  PERM_EDIT_TRANSACTIONS: "edit transactions",
  PERM_DELETE_TRANSACTIONS: "delete transactions",

  PERM_VIEW_MODULE_EVENTS: "view module events",
  PERM_VIEW_ANY_EVENTS: "view any events",
  PERM_VIEW_EVENTS: "view event",
  PERM_CREATE_EVENTS: "create event",
  PERM_EDIT_EVENTS: "edit event",
  PERM_DELETE_EVENTS: "delete event",

  PERM_VIEW_MODULE_COLLECTES: "view module collectes",
  PERM_VIEW_ANY_COLLECTES: "view any collectes",
  PERM_VIEW_COLLECTES: "view collectes",
  PERM_CREATE_COLLECTES: "create collectes",
  PERM_EDIT_COLLECTES: "edit collectes",
  PERM_DELETE_COLLECTES: "delete collectes",

  PERM_VIEW_MODULE_TEMPLATES_MAILS: "view module templates mail",
  PERM_VIEW_ANY_TEMPLATES_MAILS: "view any template mail",
  PERM_VIEW_TEMPLATES_MAILS: "view template mail",
  PERM_CREATE_TEMPLATES_MAILS: "create template mail",
  PERM_EDIT_TEMPLATES_MAILS: "edit template mail",
  PERM_DELETE_TEMPLATES_MAILS: "delete template mail",

  PERM_VIEW_MODULE_RECLAMATIONS: "view module reclamations",
  PERM_VIEW_ANY_RECLAMATIONS: "view any reclamations",
  PERM_VIEW_RECLAMATIONS: "view reclamations",
  PERM_CREATE_RECLAMATIONS: "create reclamations",
  PERM_EDIT_RECLAMATIONS: "edit reclamations",
  PERM_DELETE_RECLAMATIONS: "delete reclamations",

  PERM_VIEW_ANY_COMMENTS: "view any comments",
  PERM_VIEW_COMMENTS: "view comments",
  PERM_CREATE_COMMENTS: "create comments",
  PERM_EDIT_COMMENTS: "edit comments",
  PERM_DELETE_COMMENTS: "delete comments",

  PERM_VIEW_MODULE_CONTACT_FORMS: "view module contact forms",
  PERM_VIEW_ANY_CONTACT_FORMS: "view any contact forms",
  PERM_VIEW_CONTACT_FORMS: "view contact forms",
  PERM_CREATE_CONTACT_FORMS: "create contact forms",
  PERM_EDIT_CONTACT_FORMS: "edit contact forms",
  PERM_DELETE_CONTACT_FORMS: "delete contact forms",

  PERM_VIEW_MODULE_NEGOTIATIONS: "view module negotiations",
  PERM_VIEW_ANY_NEGOTIATIONS: "view any negotiations",
  PERM_VIEW_NEGOTIATIONS: "view negotiations",
  PERM_CREATE_NEGOTIATIONS: "create negotiations",
  PERM_EDIT_NEGOTIATIONS: "edit negotiations",
  PERM_DELETE_NEGOTIATIONS: "delete negotiations",
};
