import service from "@/store/services/payment-methods-service";

export const state = () => ({
  list: {},
  paymentMethod: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, paymentMethod) => {
    state.paymentMethod = paymentMethod;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((paymentMethod) => {
      commit("SET_RESOURCE", paymentMethod);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((paymentMethod) => {
      commit("SET_RESOURCE", paymentMethod);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((paymentMethod) => {
      commit("SET_RESOURCE", paymentMethod);
    });
  },

  validate({ commit, dispatch }, params) {
    return service.validate(params).then((paymentMethod) => {
      commit("SET_RESOURCE", paymentMethod);
    });
  },

  setAsDefault({ commit, dispatch }, params) {
    return service.setAsDefault(params, this.$axios).then((paymentMethod) => {
      commit("SET_RESOURCE", paymentMethod);
    });
  },

  activateOrDeactivate({ commit, dispatch }, params) {
    return service.activateOrDeactivate(params, this.$axios).then((paymentMethod) => {
      commit("SET_RESOURCE", paymentMethod);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  paymentMethod: (state) => state.paymentMethod,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((paymentMethod) => ({
      id: paymentMethod.id,
      name: `${paymentMethod.payment_method_type}`,
    }));
  },
};

const paymentMethods = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default paymentMethods;
