import service from "@/store/services/transactions-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  transaction: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, transaction) => {
    state.transaction = transaction;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((transaction) => {
      commit("SET_RESOURCE", transaction);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((transaction) => {
      commit("SET_RESOURCE", transaction);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((transaction) => {
      commit("SET_RESOURCE", transaction);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },
  
  cancel({ commit, dispatch }, params) {
    return service.cancel(params, this.$axios).then((transaction) => {
      commit("SET_RESOURCE", transaction);
    });
  },

  changeBuyer({ commit, dispatch }, params) {
    return service.changeBuyer(params, this.$axios).then((transaction) => {
      commit("SET_RESOURCE", transaction);
    });
  },

  validate({ commit, dispatch }, params) {
    return service.validate(params, this.$axios).then((transaction) => {
      commit("SET_RESOURCE", transaction);
    });
  },

  generateInvoices({ commit, dispatch }, params) {
    return service.generateInvoices(params, this.$axios).then((transaction) => {
      commit("SET_RESOURCE", transaction);
    });
  },
  getInvoices({ commit, dispatch }, params) {
    return service.getInvoices(params, this.$axios).then((invoices) => {
      return invoices;
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  transaction: (state) => state.transaction,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((transaction) => ({
      id: transaction.id,
      status: transaction.status
    }));
  },
};

const transactions = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default transactions;
