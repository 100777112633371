import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/transactions`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/transactions/${id}?include=seller,buyer,sellerLocation,buyerLocation,vehicle,sellerInvoice,buyerInvoice`, options)
    .then((response) => {
      let transaction = jsona.deserialize(response.data);
      delete transaction.links;
      return transaction;
    });
}

function add(transaction) {
  const payload = jsona.serialize({
    stuff: transaction,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/transactions`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(transaction) {
  const payload = jsona.serialize({
    stuff: transaction,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/transactions/${transaction.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/transactions/${id}`, options);
}

function cancel(transaction) {
  const payload = jsona.serialize({
    stuff: transaction,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/transactions/${transaction.id}/cancel`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}


function changeBuyer(transaction) {
  const payload = jsona.serialize({
    stuff: transaction,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/transactions/${transaction.id}/change-buyer`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}


function validate(transaction) {
  const payload = jsona.serialize({
    stuff: transaction,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/transactions/${transaction.id}/validate`, payload, options)
    .then((response) => {
      let invoice = jsona.deserialize(response.data);
      delete invoice.links;
      return invoice;
    });
}

function generateInvoices(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/transactions/${id}/generateInvoices?include=seller,buyer,sellerLocation,buyerLocation,vehicle`,
      options
    )
    .then((response) => {
      let invoice = jsona.deserialize(response.data);
      delete invoice.links;
      return invoice;
    });
}

function getInvoices(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/transactions/${id}/getInvoices?include=seller,buyer,sellerLocation,buyerLocation,vehicle`,
      options
    )
    .then((response) => {
      return response.data.data;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  cancel,
  validate,
  generateInvoices,
  getInvoices,
  changeBuyer
};
