import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/vehicles`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/vehicles/${id}?include=organization,allowedLocations,auction,event,highestBids.user,highestBids.location`,
      options
    )
    .then((response) => {
      let vehicle = jsona.deserialize(response.data);
      delete vehicle.links;
      return vehicle;
    });
}

function convertVehicleBooleans(vehicle) {
  const convertedVehicle = { ...vehicle };

  Object.keys(convertedVehicle).forEach((key) => {
    const value = convertedVehicle[key];
    if (typeof value === "string" && (value === "true" || value === "false")) {
      convertedVehicle[key] = value === "true";
    }
  });

  return convertedVehicle;
}

function add(vehicle) {
  var vehiculeConverted = convertVehicleBooleans(vehicle);

  const payload = jsona.serialize({
    stuff: vehiculeConverted,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/vehicles?include=organization,allowedLocations,auction,event`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(vehicle) {
  const payload = jsona.serialize({
    stuff: vehicle,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/vehicles/${vehicle.id}?include=organization,allowedLocations,auction,event`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/vehicles/${id}`, options);
}

function activate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/vehicles/${id}/activate`, options)
    .then((response) => {
      let vehicle = jsona.deserialize(response.data);
      delete vehicle.links;
      return vehicle;
    });
}

function deactivate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/vehicles/${id}/deactivate`, options)
    .then((response) => {
      let vehicle = jsona.deserialize(response.data);
      delete vehicle.links;
      return vehicle;
    });
}

function exportExcel(params) {
  const options = {
    params: params,
    responseType: "blob",
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };
  return axios.get(`${url}/vehicles/exports`, options);
}

function printPdfLetters(data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    responseType: "blob",
  };
  return axios.post(`${url}/vehicles/print-letters`, data, options);
}

function exportThumbnails() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    responseType: "blob",
  };
  return axios.get(`${url}/vehicles/export-thumbnails`, options);
}

function printLettersForSelection(data) {
  const options = {
    headers: {
      "Content-Type": "application/vnd.api+json",
    },
    responseType: "blob",
  };
  return axios.post(
    `${url}/vehicles/print-letters-for-selection`,
    data,
    options
  );
}

function exportThumbnailsForSelection(selectedVehicles, details) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    responseType: "blob",
  };
  const data = {
    selectedVehicles: selectedVehicles,
    details: details,
  };
  return axios.post(
    `${url}/vehicles/export-thumbnails-for-selection`,
    data,
    options
  );
}

function print(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };
  return axios.get(`${url}/vehicles/${id}/print-transaction-receipt`, options);
}

function createTransaction(vehicle) {
  const payload = jsona.serialize({
    stuff: vehicle,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/vehicles/${vehicle.id}/transactions`, vehicle, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroyTransaction(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/vehicles/${id}/transactions`, options);
}

function lookupApiByVin({ vin, organization }) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },

    params: {
      organization,
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(`${url}/vehicles/get-by-vin/${vin}`, options)
    .then((response) => {
      let vehicles = response.data;
      return vehicles;
    });
}

function lookupWorstPriceByVin(vin) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/vehicles/get-worst-price-by-vin/${vin}`, options)
    .then((response) => {
      let vehicles = response.data;
      return vehicles;
    });
}

function validate(data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/vehicles/${data.id}/validate`, data, options);
}

function invalidate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/vehicles/${id}/invalidate`, options);
}

function closeAuction(id) {
  const options = {
    params: {
      force: 1,
    },
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/vehicles/${id}/close-auction`, options);
}

function cancelSale(id) {
  const options = {
    params: {
      force: 1,
    },
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/vehicles/${id}/cancel-sale`, options);
}

function confirmSale(id) {
  const options = {
    params: {
      force: 1,
    },
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/vehicles/${id}/confirm-sale`, options);
}

function reauction(id) {
  const options = {
    params: {
      force: 1,
    },
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/vehicles/${id}/re-auction`, options)
    .then((response) => jsona.deserialize(response.data));
}

export default {
  list,
  get,
  add,
  print,
  update,
  destroy,
  activate,
  deactivate,
  exportExcel,
  printPdfLetters,
  exportThumbnails,
  createTransaction,
  destroyTransaction,
  printLettersForSelection,
  exportThumbnailsForSelection,
  lookupWorstPriceByVin,
  lookupApiByVin,
  validate,
  invalidate,
  closeAuction,
  cancelSale,
  confirmSale,
  reauction,
};
