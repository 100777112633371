import Echo from "laravel-echo";
import Pusher from "pusher-js";
import axios from "axios";

export default {
  install(Vue) {
    window.Pusher = Pusher;

    const url = process.env.VUE_APP_API_BASE_URL;

    Vue.prototype.$laravelEcho = window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.VUE_APP_PUSHER_APP_KEY,
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      forceTLS: true,
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            axios
              .post(`${url}/broadcasting/auth`, {
                socket_id: socketId,
                channel_name: channel.name,
              })
              .then((response) => callback(null, response.data))
              .catch((error) => callback(error));
          },
        };
      },
    });
  },
};
