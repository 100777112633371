import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/bids`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/bids/${id}?include=user,location.organization`, options)
    .then((response) => {
      let vehicle = jsona.deserialize(response.data);
      delete vehicle.links;
      return vehicle;
    });
}

function add(params) {
  const { isAuctionEnded, ...bid } = params;

  const uri = isAuctionEnded
    ? `${url}/bids/add-bid?include=user,location.organization`
    : `${url}/bids?include=user,location.organization`;


  const payload = jsona.serialize({
    stuff: bid,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(uri, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(bid) {
  const payload = jsona.serialize({
    stuff: bid,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/bids/${bid.id}?include=user,location.organization`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/bids/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
