import service from "@/store/services/purchases-invoices-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  purchasesInvoice: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, purchasesInvoice) => {
    state.purchasesInvoice = purchasesInvoice;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((purchasesInvoice) => {
      commit("SET_RESOURCE", purchasesInvoice);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((purchasesInvoice) => {
      commit("SET_RESOURCE", purchasesInvoice);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((purchasesInvoice) => {
      commit("SET_RESOURCE", purchasesInvoice);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  validate({ commit, dispatch }, params) {
    return service.validate(params, this.$axios).then((purchasesInvoice) => {
      commit("SET_RESOURCE", purchasesInvoice);
    });
  },

  cancel({ commit, dispatch }, params) {
    return service.cancel(params, this.$axios).then((invoice) => {
      commit("SET_RESOURCE", invoice);
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  purchasesInvoice: (state) => state.purchasesInvoice,
  url: (state) => state.url,
};

const purchasesInvoices = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default purchasesInvoices;
